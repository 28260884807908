import { Accordion, AccordionSummary, AppBar, Avatar, Box, Drawer, IconButton, List, Menu, MenuItem, Tooltip, Typography,} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { assests } from "../../../Assets/assets";
import { redirectToUrl } from "../../../Utils/helper";
  
  const MobileMasthead = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const authStore = window.localStorage.getItem('AuthStore')
    const token = window.localStorage.getItem('token')
    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const navigate = useNavigate()
  
    const logout = () => {
      localStorage.clear();
      navigate('/login')
    }
  
    
    return (
      <Box className="new-mobile-masthead">
        <AppBar
          position="static"
          sx={{
            background: "white",
            // marginBottom: "50px"
          }}
        >
          <Toolbar sx={{justifyContent: 'space-between', padding: '15px 0px'}}>
            <Box>
              <img style={{width: '172px', height: '61px', marginLeft: '20px'}} src={assests.dogstaysLogo}/>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <img style={{width: '51px', height: '41px', marginRight: '50px'}} src={assests.Luxembourg}></img>
                <IconButton size="large" edge="start" color="black" aria-label="menu" sx={{ mr: 2 }} className="hamburger-icon-btn" onClick={toggleDrawer}>
                <MenuIcon />
                </IconButton>
            </Box>

          </Toolbar>
        </AppBar>
  
        {/* Drawer UI */}
        <Drawer anchor={"left"} open={isDrawerOpen}>
          <Box sx={{ width: 350 }} role="presentation">
            <Box sx={{display: 'flex', mt:2}}>
            <Box sx={{ display: { xs: "flex" }, flexGrow: "2" }}>
                <img src={assests.dogstaysLogo} style={{width: '172px', height: '61px', marginLeft: '20px'}} />
              </Box>
              <IconButton size="large" edge="start" color="black" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer} className="close-hamburger-icon">
                <CloseIcon />
              </IconButton> 
            </Box>
            <List>
              <Box sx={{ textAlign: 'center', mt: 4}}>
                {authStore && token && <Accordion>
                    <AccordionSummary >
                      <p style={{margin: 0}} onClick={() => navigate('/sitter/home')}>Home</p>
                    </AccordionSummary>
                </Accordion>}
                {!authStore && !token &&<Accordion>
                    <AccordionSummary >
                      <p style={{margin: 0}} onClick={() => navigate('/login')}>Login as Dog sitter</p>
                    </AccordionSummary>
                </Accordion>}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" >
                      <p style={{margin: 0}} onClick={() => redirectToUrl('https://www.dogstays.lu/services')}>Services</p>
                    </AccordionSummary>
                    <AccordionDetails  onClick={() => redirectToUrl('https://www.dogstays.lu/services/#overnight')} sx={{textAlign: 'start'}}>Overnight</AccordionDetails>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/services/#daycare')} sx={{textAlign: 'start'}}>Daycare</AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" >
                      <p style={{margin: 0}} onClick={() => redirectToUrl('https://www.dogstays.lu/book')}>Book a Dog Sitter</p>
                    </AccordionSummary>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/book/#process')} sx={{textAlign: 'start'}}>Process</AccordionDetails>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/book/#pricing')} sx={{textAlign: 'start'}}>Pricing</AccordionDetails>
                </Accordion>
                {!authStore && !token && <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                      <p style={{margin: 0}} onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters')}>For Dog Sitter</p>
                    </AccordionSummary>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters/#application-process')} sx={{textAlign: 'start'}}>Our Application Process</AccordionDetails>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters/#testimonials')} sx={{textAlign: 'start'}}>Dog Sitter Testimonials</AccordionDetails>
                </Accordion>}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                      <p style={{margin: 0}} onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters')}>FAQ</p>
                    </AccordionSummary>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/faqs-parents')} sx={{textAlign: 'start'}}>Dog Parent FAQs</AccordionDetails>
                    <AccordionDetails  onClick={() => redirectToUrl('https://www.dogstays.lu/faqssitters')} sx={{textAlign: 'start'}}>Dog Sitter FAQs</AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" >More</AccordionSummary>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/media-coverage')} sx={{textAlign: 'start'}}>Media Coverage</AccordionDetails>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/about-us')} sx={{textAlign: 'start'}}>About Us</AccordionDetails>
                    <AccordionDetails onClick={() => redirectToUrl('https://www.dogstays.lu/contact')}sx={{textAlign: 'start'}}>Contact Us</AccordionDetails>
                </Accordion>
                {authStore && token && <Accordion>
                    <AccordionSummary >
                      <p style={{margin: 0}} onClick={logout}>Logout</p>
                    </AccordionSummary>
                </Accordion>}
              </Box>
            </List>
          </Box>
        </Drawer>
      </Box>
    );
  };
  
  export default MobileMasthead;
  
