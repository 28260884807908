import { Avatar, Box, Button, Grid, NativeSelect, Tooltip, Typography } from "@mui/material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Masthead from "../../../../../Components/Masthead";
import {
  CustomLabelInput,
  FormInput,
  InputField,
} from "../../../../../Components/CustomElements";
import { useEffect, useMemo, useState } from "react";
import { ImageUpload, deleteAddress, getSelfProfile, updateAddressById, updateParentInfo } from "../../../../../Utils/Api";
import Loader from "../../../../../Components/Loader";
import { languagesOptions } from "../../../../../helper";
import Select from "react-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import DeleteAddressDialog from "../../../../../Components/Dialogs/deleteAddress";
import ChangePasswordDialog from "../../../../../Components/Dialogs/changePassword";
import SnackBar from "../../../../../Components/Snackbar";
import AddIcon from '@mui/icons-material/Add';
import AddNewAddress from "../../../../../Components/Dialogs/addNewAddress";
import { BackBtn } from "../../../../../Assets/assets";
import { useNavigate } from "react-router-dom";
import { setItem } from "../../../../../Utils/localStorage";
import { formatArrayForCountry } from "../../../../../Utils/helper";
import UpdateMobileNumberDialog from "../../../../../Components/Dialogs/updateMobile";

const MyProfile = () => {
  const [userInfo, setUserInfo] = useState({
    another_whatsapp_mobile: null,
    date_of_birth: null,
    email: null,
    first_name: null,
    gender: null,
    hear_from_source: null,
    last_name: null,
    mobile: null,
    preferred_languages: [],
    using_whatsapp_messenger: null,
    photo: null,
    address: [],
  });
  const [addressInfo, setAddressInfo] = useState({
    street_name: '',
    postal_code: '',
    city: '',
    country: ''
  })
  const [dialogStates, setDialogStates] = useState({ isDeleteDialogOpen: false, isPasswordDialogOpen: false, isAddressDialogOpen: false, isUpdateMobileDialogOpen: false })
  const { isDeleteDialogOpen, isAddressDialogOpen, isPasswordDialogOpen, isUpdateMobileDialogOpen } = dialogStates
  const [currentAddressId, setCurrentAddressId] = useState('')
  const { another_whatsapp_mobile, date_of_birth, email, first_name, gender, hear_from_source, last_name, mobile, preferred_languages, using_whatsapp_messenger, photo, address } = userInfo;
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true)
  const [isAddressDisabled, setIsAddressDisabled] = useState(address.map(() => false))
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: "", type: "" })
  const lang = preferred_languages.map((res) => res.value).toString()
  const navigate = useNavigate()
  const countryOptions = useMemo(() => countryList().getData(), [])
  const alphabetRegex = /^[A-Za-z]+$/

  const checkStringValidate = (value, inputName) => {
    if(alphabetRegex.test(value)){
      return true
    }else{
      setSnackbar({ ...snackbar, isOpen: true, message: 'Only alphabets characters', type: 'error' })
      setUserInfo({ ...userInfo, [inputName]: '' })
    }
  }

  const formatLanguageArray = (arr) => {
    let updatedArray = [];
    for (let i in arr) {
      updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] });
    }
    return updatedArray;
  };

  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value)
    return formatedData
  }

  const getUserProfileData = () => {
    setLoading(true);
    getSelfProfile().then((res) => {
      const data = res.data.data;
      setUserInfo({
        ...userInfo,
        another_whatsapp_mobile: data.another_whatsapp_mobile,
        date_of_birth: data.date_of_birth,
        email: data.email,
        first_name: data.first_name,
        gender: data.gender,
        hear_from_source: data.hear_from_source,
        last_name: data.last_name,
        mobile: data.mobile,
        preferred_languages: formatLanguageArray(data.preferred_languages),
        using_whatsapp_messenger: data.using_whatsapp_messenger,
        photo: data.photo,
        address: data.address,
      });
      setAddressInfo({
        ...addressInfo,
        street_name: data.address.street_name,
        postal_code: data.address.postal_code,
        city: data.address.city,
        country: data.address.country
      })
      setLoading(false);
    });
  }

  const handleDeleteAddressById = async () => {
    try {
      setLoading(true)
      await deleteAddress(currentAddressId)
      setDialogStates({ ...dialogStates, isDeleteDialogOpen: false })
      setLoading(false)
      getUserProfileData()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserProfileData()
  }, []);

  const handleUploadProfileImage = (e) => {
    // let reader = new FileReader();
    // let file = e.target.files[0];
    //   console.log(reader.readAsDataURL(file))

      const uploadFile = e.target.files[0];
      const fileSize = e.target.files[0].size;
      const imageFormData = new FormData();
      imageFormData.append("image", uploadFile);
      fileSize < "5000000"
        ?  ImageUpload(imageFormData).then((res) => {
          const url = res.data.data.url[0]
          if(res.data.response_code == 80){
            updateParentInfo({
              photo: url
              }).then((res) => {
                const authStore = window.localStorage.getItem('AuthStore')
                const serialisedData = JSON.parse(authStore)
                const newResponse = res.data.data
                const merge = {...serialisedData, ...newResponse}
                setItem('AuthStore', JSON.stringify(merge))
                setSnackbar({...snackbar, isOpen: true, message: "Profile Image Uploaded", type: 'success'})
                getUserProfileData()
              })
          }
        })
        : setSnackbar({ ...snackbar, isOpen: true, message: 'Select image under 5Mb', type: 'error' })


  }

  const handleUpdateUserDetails = () => {
    if(first_name && last_name && date_of_birth && gender && (preferred_languages.length > 1)){
      updateParentInfo({
        first_name: first_name,
        last_name: last_name,
        date_of_birth: date_of_birth,
        gender: gender,
        another_whatsapp_mobile: another_whatsapp_mobile,
        preferred_languages: formatMultiSelectData(preferred_languages),
      }).then((res) => {
        if (res.data.response_code == 80) {
          setIsDisabled(true)
          setSnackbar({ ...snackbar, isOpen: true, message: `Profile Updated`, type: 'success' })
        } else if (res.data.response_code == 90) {
          setSnackbar({ ...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error' })
        }
      })
    }else{
      setSnackbar({ ...snackbar, isOpen: true, message: 'Please fill all the details', type: 'error' })
    }

  }

  const handleEnableInputField = (index) => {
    const newInputStates = [...isAddressDisabled];
    newInputStates[index] = !newInputStates[index];
    setIsAddressDisabled(newInputStates);
  }

  const handleUpdateAddressById = (item, index) => {
  const addressId = item.id
  updateAddressById(addressInfo, addressId).then((res) => {
    if (res.data.response_code === 80) {
      setSnackbar({ ...snackbar, isOpen: true, message: `Address Updated`, type: 'success' });
      setIsAddressDisabled(address.map(() => false))
      getUserProfileData()
    } else {
      setSnackbar({ ...snackbar, isOpen: true, message: `Address Update Failed`, type: 'error' });
    }
  })
  }
  return (
    <Box>
      <Masthead />
      {loading ? (
        <Loader />
      ) : (
        <Box className='profile-container' sx={{ maxWidth: "85%", margin: "auto", mb: 5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard') }}>{BackBtn()} Back</Box>
            <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
            <Typography
              component="h1"
              fontFamily="Brandon"
              sx={{
                width: "100%",
                mt: 5,
                mb: 5,
                fontSize: "28px",
                textAlign: 'center'
              }}
            >
              My Profile
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "50px", alignItems: "center" }}>
            <Box sx={{ position: "relative", width: "max-content" }}>
              {photo ? (
                <Avatar src={photo} sx={{ width: "150px", height: "150px", border: '1px solid #80808075' }} />
              ) : (
                <Avatar sx={{ width: "150px", height: "150px", border: '1px solid #80808075' }} />
              )}
              <Tooltip title="Upload">
                <Button
                  sx={{
                    position: "absolute",
                    right: "-10px",
                    top: "0px",
                    cursor: "pointer",
                  }}
                  component="label"
                >
                  <EditNoteOutlinedIcon
                    sx={{
                      background: "#f48220",
                      color: "white",
                      borderRadius: "6px",
                      padding: "2px",
                    }}
                  />
                  <input
                    accept="image/*"
                    type="file"
                    hidden
                    onChange={handleUploadProfileImage}
                  />
                </Button>
              </Tooltip>
            </Box>
            <Box>
              <Typography
                fontFamily="Brandon"
                sx={{
                  width: "100%",
                  mt: 1,
                  fontSize: "14px",
                  color: "#f48220",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setDialogStates({ ...dialogStates, isUpdateMobileDialogOpen: true })}
              >
                {" "}
                Change Phone Number{" "}
              </Typography>
              <Typography
                fontFamily="Brandon"
                onClick={() => setDialogStates({ ...dialogStates, isPasswordDialogOpen: true })}
                sx={{
                  width: "100%",
                  mt: 1,
                  fontSize: "14px",
                  color: "#f48220",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {" "}
                Change Password{" "}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 5,
            }}
          >
            <Typography
              fontFamily="Brandon"
              sx={{
                width: "100%",
                mb: 1,
                fontSize: "22px",
                textDecoration: "underline",
              }}
            >
              My Information
            </Typography>
            {isDisabled ? <EditNoteOutlinedIcon
              onClick={() => setIsDisabled(false)}
              sx={{
                background: "#f48220",
                color: "white",
                borderRadius: "6px",
                padding: "2px",
                cursor: "pointer",
              }}
            /> : <CancelRoundedIcon
              onClick={() => setIsDisabled(true)}
              sx={{
                background: "#f48220",
                color: "white",
                borderRadius: "6px",
                padding: "2px",
                cursor: "pointer",
              }}
            />}
          </Box>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                  First Name
                </CustomLabelInput>
                <InputField
                  className={isDisabled ? "" : "disabled-field"}
                  type="text"
                  size="small"
                  placeholder="Enter Name"
                  name="first_name"
                  value={first_name}
                  onChange={(e) => checkStringValidate(e.target.value, e.target.name) && setUserInfo({ ...userInfo, first_name: e.target.value })}
                  disabled={isDisabled}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                  Last Name
                </CustomLabelInput>
                <InputField
                  className={isDisabled ? "" : "disabled-field"}
                  type="text"
                  size="small"
                  placeholder="Enter Name"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => checkStringValidate(e.target.value, e.target.name) && setUserInfo({ ...userInfo, last_name: e.target.value })}
                  disabled={isDisabled}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                  Date of Birth
                </CustomLabelInput>
                <InputField
                className={isDisabled ? "" : "disabled-field"}
                  type="date"
                  size="small"
                  placeholder="Enter Name"
                  value={date_of_birth}
                  onChange={(e) => setUserInfo({ ...userInfo, date_of_birth: e.target.value })}
                  disabled={isDisabled}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true">Gender</CustomLabelInput>
                <NativeSelect
                className={isDisabled ? "" : "disabled-field"}
                  disableUnderline
                  defaultValue=""
                  value={gender}
                  disabled={isDisabled}
                  onChange={(e) => setUserInfo({ ...userInfo, gender: e.target.value })}
                  inputProps={{
                    style: {
                      fontFamily: "Brandon",
                      padding: "8.5px 14px",
                    },
                  }}
                  sx={{
                    border: "1px solid #ced4d9",
                    borderRadius: "3px",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  <option selected disabled value="">
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput
                  shrink="true"
                  htmlFor="another_whatsapp_mobile"
                  sx={{ marginBottom: "5px" }}
                >
                  Which languages do you speak or understand?
                </CustomLabelInput>
                {!isDisabled ? <Select
                  className={isDisabled ? "select-font" : "disabled-field select-font"}
                  // className="select-font"
                  options={languagesOptions}
                  value={preferred_languages}
                  isMulti
                  onChange={(value) => setUserInfo({ ...userInfo, preferred_languages: value })}
                  name="preferred_languages"
                  placeholder="Please select multiple languages"
                /> :
                  <InputField
                    type="text"
                    size="small"
                    value={lang}
                    disabled={isDisabled}
                  />
                }
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput
                  sx={{ mb: 1 }}
                  shrink="true"
                  htmlFor="date_of_birth"
                >
                  WhatsApp Number
                </CustomLabelInput>
                <PhoneInput
                className={isDisabled ? "" : "disabled-field"}
                  country={"lu"}
                  value={another_whatsapp_mobile}
                  onChange={(value) => setUserInfo({ ...userInfo, another_whatsapp_mobile: value })}
                  disabled={isDisabled}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput
                  sx={{ mb: 1 }}
                  shrink="true"
                >
                  Mobile Number
                </CustomLabelInput>
                <PhoneInput
                  className={isDisabled ? "" : "disabled-field"}
                  country={"lu"}
                  value={mobile}
                  disabled={true}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'center' }}>
                {!isDisabled && <Button className="default-btn" variant="contained" onClick={handleUpdateUserDetails} sx={{ mt: 3, mb: 5, width: "25%" }}>Update</Button>}
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 5,
                mb: 5
              }}
            >
              <Typography
                fontFamily="Brandon"
                sx={{
                  width: "100%",
                  mb: 1,
                  fontSize: "22px",
                  textDecoration: "underline",
                }}
              >
                My Addresses
              </Typography>
              <AddIcon
                onClick={() => setDialogStates({ ...dialogStates, isAddressDialogOpen: true })}
                sx={{
                  background: "#f48220",
                  color: "white",
                  borderRadius: "6px",
                  padding: "2px",
                  cursor: "pointer",
                }} />
            </Box>
            {address.length > 0 ? address.map((item, index) => {
              return <>
                <Box className='profile-all-address'>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 5,
                  }}>
                    <Typography
                      fontFamily="Brandon"
                      sx={{
                        width: "100%",
                        mb: 1,
                        fontSize: "18px",
                      }}
                    >
                      Address: {index + 1}
                    </Typography>
                    {!isAddressDisabled[index] ? 
                    <Tooltip title="Edit">
                      <EditNoteOutlinedIcon
                        onClick={() => handleEnableInputField(index)}
                        sx={{
                          background: "#f48220",
                          color: "white",
                          borderRadius: "6px",
                          padding: "2px",
                          mr: 1,
                          cursor: 'pointer'
                        }}
                      />
                  </Tooltip>
                  :
                  <Tooltip title="Undo">
                      <CancelRoundedIcon
                        onClick={() => handleEnableInputField(index)}
                        sx={{
                          background: "#f48220",
                          color: "white",
                          borderRadius: "6px",
                          padding: "2px",
                          mr: 1,
                          cursor: 'pointer'
                        }}
                      />
                  </Tooltip>
                  }
                    {address.length != 1 && <Tooltip title="Delete">
                    <DeleteForeverIcon
                      onClick={() => {
                        setCurrentAddressId(item.id)
                        setDialogStates({ ...dialogStates, isDeleteDialogOpen: true })
                      }}
                      sx={{
                        background: "#f48220",
                        color: "white",
                        borderRadius: "6px",
                        padding: "2px",
                        cursor: "pointer",
                      }}
                    /></Tooltip>}
                  </Box>
                  <Grid container xs={12} spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                          Street Name and Number
                        </CustomLabelInput>
                        <InputField
                          className={!isAddressDisabled[index] ? "" : "disabled-field"}
                          type="text"
                          size="small"
                          placeholder="Enter Street Name and Number"
                          value={!isAddressDisabled[index] ? item.street_name : addressInfo.street_name}
                          onChange={(e) => setAddressInfo({...addressInfo, street_name: e.target.value})}
                          disabled={!isAddressDisabled[index]}
                        />
                      </FormInput>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                          Postal Code
                        </CustomLabelInput>
                        <InputField
                          className={!isAddressDisabled[index] ? "" : "disabled-field"}
                          type="text"
                          size="small"
                          placeholder="Enter Postal Code"
                          value={!isAddressDisabled[index] ? item.postal_code : addressInfo.postal_code}
                          onChange={(e) => setAddressInfo({...addressInfo, postal_code: e.target.value})}
                          disabled={!isAddressDisabled[index]}
                        />
                      </FormInput>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                        Area Name + City (Example: Merl, Luxembourg City) 
                        </CustomLabelInput>
                        <InputField
                          className={!isAddressDisabled[index] ? "" : "disabled-field"}
                          type="text"
                          size="small"
                          placeholder="Enter City"
                          value={!isAddressDisabled[index] ? item.city : addressInfo.city}
                          onChange={(e) => setAddressInfo({...addressInfo, city: e.target.value})}
                          disabled={!isAddressDisabled[index]}
                        />
                      </FormInput>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                          Country
                        </CustomLabelInput>
                        {!isAddressDisabled[index] ? 
                        <InputField
                          type="text"
                          size="small"
                          value={item.country}
                          disabled={!isAddressDisabled[index]}
                        />
                        :
                        <Select
                        className={!isAddressDisabled[index] ? "select-font margin-top-10" : "disabled-field select-font margin-top-10"}
                        options={countryOptions}
                        value={addressInfo.country == item.country ? formatArrayForCountry(addressInfo.country) : formatArrayForCountry(item.country)}
                        onChange={(value) => setAddressInfo({...addressInfo, country: value.label})}
                      />
                        }
                      </FormInput>
                    </Grid>
                  </Grid>
                {isAddressDisabled[index] && <Box sx={{textAlign: 'center'}}><Button className="default-btn" variant="contained" onClick={() => handleUpdateAddressById(item)} sx={{ mt: 3, mb: 5, width: "25%" }}>Update</Button></Box>}
                </Box>

              </>
            }) :
              <Typography fontFamily="Brandon" sx={{ width: "100%", mb: 1, fontSize: "22px", textAlign: 'center', color: '#f48220' }} > No Address Added </Typography>
            }


          </Box>
          <DeleteAddressDialog isOpen={isDeleteDialogOpen} handleDeleteAddress={handleDeleteAddressById} handleCloseDialog={() => setDialogStates({ ...dialogStates, isDeleteDialogOpen: false })} />
          <ChangePasswordDialog isOpen={isPasswordDialogOpen} handleCloseDialog={() => setDialogStates({ ...dialogStates, isPasswordDialogOpen: false })} />
          <AddNewAddress isOpen={isAddressDialogOpen} refresh={getUserProfileData} handleCloseDialog={() => setDialogStates({ ...dialogStates, isAddressDialogOpen: false })} />
          <UpdateMobileNumberDialog isOpen={isUpdateMobileDialogOpen} handleCloseDialog={() => setDialogStates({ ...dialogStates, isUpdateMobileDialogOpen: false })} reRenderData={() => getUserProfileData()}/>
        </Box>
      )}
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={3000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </Box>

  );
};

export default MyProfile;
